@import "colors";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  color: $primary-blue;
  font-size: 16px;
}

html, body {
  height: 100%;
  background-color: $bg-lower;
}

body {
  margin: 0
}

.text {
  &--bold {
    font-weight: 600;
  }

  &--blue {
    color: $secondary-blue;
  }

  &--gray {
    color: $gray-blue;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.supra-logo-small {
  text-align: center;
  margin-bottom: 20px;

  img {
    width: 90px;
    height: auto;
    border-radius: 30px;
  }
}

.supra-snack-bar {
  border-radius: 5px;

  div {
    padding: 0;
    border-radius: 5px;
  }
}


.no-wrap {
  white-space: nowrap;
  overflow: hidden;
}

.supra-checkbox {
  width: 100%;

  .mdc-form-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    label {
      width: 100%;
      font-size: 1.2rem;
    }
  }
}

.table-container {
  overflow-x: auto;

  .show-hover {
    .hide-actions {
      opacity: 0.1;
    }

    &:hover {
      background-color: $light-gray;

      .hide-actions {
        opacity: 1;
      }
    }
  }
}

.mdc-tab__text-label {
  font-size: 16px;

}

.empty-state {
  &__container {
    width: 100%;
  }
}

.spinner {
  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }
}

.button-spinner {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  border-top-color: $primary-blue;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.action-button {
  width: 100%;
  height: 50px;
}

.dialog-container {

  .mat-mdc-dialog-surface {
    padding: 40px 10px 40px 10px;
    border-radius: 20px;
    width: 95vw;
    @media (min-width: 768px) {
      & {
        width: 50vw;
      }
    }
  }

}

.navigation-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 768px) {
  .hide-mobile {
    display: none !important;
  }
}

.supra-transaction-card {
  &__icon {
    border-radius: 10px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    p {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 0;

      strong {
        font-weight: 500;
        font-size: 12px;
        color: $gray-500;
        line-height: 140%;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
      }
    }
  }
}
