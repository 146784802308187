// Semantic Colors
$error-light: #FFF8FA;
$error: #D92D21;
$error-dark: #55170D;

$warning-light: #FFFCF5;
$warning: #F69007;
$warning-dark: #7A2E0D;

$info-light: #EBF5FF;
$info: #296DEA;
$info-dark: #002467;

$success-light: #F7FEF9;
$success: #047647;
$success-dark: #074D31;

$primary-blue-light : #9AA3B1;
$primary-blue-medium-light : #4B5565;
$primary-blue-dark-light : #13153A;
$primary-blue: #00022D;

$gray-50: #f6f7f9;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d2d5da;
$gray-400: #9ca3af;
$gray-500: #6d7280;
$gray-600: #4b5563;
$gray-700: #374151;
$gray-800: #1f2937;
$gray-900: #111827;

$white: #ffffff;

//COLORS BELOW ARE OLD

$secondary-blue: #1565D8;
$accent-green: #32ef76;
$secondary-light-blue: #CCE0FF;

$success-green: #e8f8ed;
$success-dark-green: #03543F;
$error-red: #FF6A64;
$warning-yellow: #FFB847;

$light-gray: #F4F6FF;
$neutral-light-gray: $gray-200;
$neutral-medium-gray: #8692A6;
$neutral-dark-gray: #2F3665;


$gray-blue: #929EAA;
$bg-lower: $gray-50;

$warning-light: #fff8e1;
$error-light: #ffebee;
$success-light: #e8f5e9;

