@use '@angular/material' as mat;
@import '_colors';
@include mat.core();

$primary-palette: mat.m2-define-palette((
  50: #E3F2FD,
  100: #BBDEFB,
  200: #90CAF9,
  300: #64B5F6,
  400: #42A5F5,
  500: $primary-blue,
  600: #1E88E5,
  700: #1976D2,
  800: #1565D8,
  900: #0D47A1,
  contrast: (
    500: $white
  )
));

$accent-palette: mat.m2-define-palette((
  50: #E8F5E9,
  100: #C8E6C9,
  200: #A5D6A7,
  300: #81C784,
  400: #66BB6A,
  500: $accent-green,
  600: #43A047,
  700: #388E3C,
  800: #2E7D32,
  900: #1B5E20,
  contrast: (
    500: $white
  )
));

$error-palette: mat.m2-define-palette((
  50: #FFEBEE,
  100: #FFCDD2,
  200: #EF9A9A,
  300: #E57373,
  400: #EF5350,
  500: $error-red,
  600: #E53935,
  700: #D32F2F,
  800: #C62828,
  900: #B71C1C,
  contrast: (
    500: $white
  )
));

//$my-font-family: 'Montserrat', sans-serif;
//
//$my-typography: mat.m2-define-typography-config(
//  $font-family: $my-font-family,
//  $headline-1: mat.m2-define-typography-level($font-size: 30px),
//  $headline-2: mat.m2-define-typography-level(20px, 24px, 400),
//  $headline-3: mat.m2-define-typography-level(18px, 22px, 400),
//  $body-1: mat.m2-define-typography-level(16px, 24px, 400),
//  $button: mat.m2-define-typography-level(14px, 16px, 500)
//);

$supra-theme: mat.m2-define-light-theme((
  color: (
    primary: $primary-palette,
    accent: $accent-palette,
    warn: $error-palette,
  )
));

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: none;
  --mdc-snackbar-container-shape: 5px;
}

.mat-tertiary {
  background-color: #DCE0E5 !important;
  color: #333 !important;
}

.mat-accent {
  color: $primary-blue !important;
}

h1 {
  font-size: 20px !important;
  margin: 0 !important;
  line-height: 22px !important;
  font-weight: 600 !important;
}

h2 {
  font-size: 18px !important;
  margin: 0 !important;
  line-height: 20px !important;
  font-weight: 600 !important;
}

h3 {
  font-size: 16px !important;
  margin: 0 !important;
  line-height: 18px !important;
  font-weight: 600 !important;
}

mat-form-field.small {
  --mat-form-field-container-height: 40px;
  --mat-form-field-container-vertical-padding: 10px;
}


@include mat.all-component-themes($supra-theme);
