@import "colors";
.supra-input {
  width: 100%;
  margin-bottom: 20px;

  mat-hint {
    color: $gray-blue;
  }

  mat-form-field {
    width: 100%;
    font-size: 1rem;
    --mdc-outlined-text-field-container-shape: 10px;
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

.expandable-overlay-panel {
  width: 100%;
  min-width: min-content;
}
